import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { wrapper, color, breakpoints, sizing, border } from '../../utils/style';
import RecentPosts from '../../components/Content/RecentPosts';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

// Imagrs
import person from '../../img/person.svg';

const Wrapper = styled('div')`
  & > h1 {
    margin-bottom: 0;
    margin-top: 30px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: ${color.lightningYellow};
  }

  & .about-us-title {
    width: 100%;
    margin-bottom: -100px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .iconImage {
      width: 83px;
      margin: 0;

      @media (max-width: ${breakpoints.xs}) {
        width: 65px;
      }
    }

    & > h3 {
      padding: 0;
      margin: 0;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 44px;
      font-weight: bold;
      color: ${color.lightningYellow};
      line-height: 65px;
    }

    @media (max-width: ${breakpoints.xs}) {
      .iconImage {
        width: 65px;
      }
      & > h3 {
        line-height: 65px;
      }
    }
  }

  & .about-banner {
    width: 100%;
    padding-top: 5px;
    margin: 0 auto -45px;

    position: sticky;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .about-text {
      margin: 0;

      &:after {
        content: 'โปรโมชั่น';
        color: ${color.white};
        font-family: Kanit-Bold, Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 184px;
        font-weight: bold;
        opacity: 0.061;
        transform: translate(-456 -266);
        text-transform: uppercase;
      }
    }

    & .about-background {
      opacity: 0.27;
      -webkit-filter: blur(46px);
      filter: blur(46px);
      background-color: #004cef;

      width: 80%;
      height: 360px;
      position: absolute;
      top: 50%;
      border-radius: 30%;
      pointer-events: none;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    padding: 30px 20px;

    & .about-banner {
      & .about-text {
        &:after {
          font-size: 150px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    & .about-banner {
      & .about-text {
        &:after {
          font-size: 60px;
          display: flex;
          height: 150px;
        }
      }
    }
  }

  ${wrapper};
  margin: 20px auto;

  .gatsby-image-wrapper {
    height: 140px;
  }
  .breadcrumbs {
    //position: absolute;
    //left: 20px;
    //top: 200px;
    margin-bottom: 20px;

    @media (max-width: ${breakpoints.tl}) {
      top: 125px;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  p {
    margin-bottom: 1rem;
    @media (max-width: ${breakpoints.md}) {
      padding: 0 15px 10px;
    }
  }

  div p {
    padding: 0;
  }
`;

const Bg = styled('div')`
  background-color: ${color.blockPurple};
  border-radius: ${border.borderRadius4};
`;
const RelatedWrapper = styled('div')`
  ${wrapper};
  margin-top: 10px;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 1rem;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }

    & > div {
      padding-top: 0;
      margin-top: 60px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: block;
      text-align: left;
      line-height: 1.3;
      color: ${color.blue};
      font-weight: ${sizing.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

const ContentWrapper = styled('div')`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  position: relative;
  line-height: 1.57;
  color: ${color.alabaster};
  @media (max-width: ${breakpoints.md}) {
    width: auto;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 13px;
    line-height: 1.62;
    color: ${color.alabaster};
    padding: 0 15px;

    & p {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    & p {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    @media (orientation: portrait) {
      margin-top: 0;
      @media (min-height: ${breakpoints.md}) {
        margin-top: 0;
      }
    }
    @media (orientation: landscape) {
      margin-top: 0;
    }
  }
  .lnwasia-text {
    font-size: 24px;
    font-weight: bold;
    
    &.hotline {
      color: ${color.darkPink};
    }

    @media (max-width: ${breakpoints.md}) {
      font-size: 13px;
    }
  }

  .second-text {
    font-size: 24px;
    font-weight: bold;
    color: ${color.lightGreen};

    @media (max-width: ${breakpoints.md}) {
      font-size: 13px;
    }
  }
}
`;

const PageWrapper = styled('div')`
  display: inline-flex;

  @media (max-width: ${breakpoints.md}) {
    &.contact-us {
      display: flex;
      flex-direction: column-reverse;

      & .contact-us-content {
        margin-top: 20px;
        padding: 0;
      }
    }
  }
`;

const Date = styled('p')`
  color: ${color.yellow};
  margin: 30px 0 1.5rem;

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px !important;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px !important;
  }
`;

const PageTemplateAboutUs = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const { breadcrumbs } = pageContext;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <Wrapper>
        <SEO
          title={metaTitle}
          description={metaDesc}
          pathname={currentPage.path}
          breadcrumbs={breadcrumbs}
          socialImage={socialImage}
        />
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentPage.title} />
        <div className="about-us-title">
          <img className="iconImage" src={person} alt="persons" />
          <h3>โปรโมชั่น</h3>
        </div>
        <div className="about-banner">
          <div className="about-text" />
          <div className="about-background" />
        </div>
        <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />

        <Date>{currentPage.date}</Date>
        <PageWrapper className="">
          <ContentWrapper className="" dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </PageWrapper>
      </Wrapper>
      {relatedIds.length > 0 && (
        <Bg>
          <RelatedWrapper>
            <RecentPosts />
          </RelatedWrapper>
        </Bg>
      )}
    </Layout>
  );
};

export default PageTemplateAboutUs;

export const pageQuery = graphql`
  query ($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
